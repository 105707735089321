<template>
  <div v-if="showDoc">
    <slot></slot>
  </div>
  <component
    :is="componentName"
    v-else
  />
</template>

<script>
export default {
  components: {
    Forbidden: () => import('@/pages/forbidden.vue'),
    NotFound: () => import('@/pages/error404.vue')
  },
  computed: {
    componentName () {
      switch (this.cache?.status ?? '') {
        case 403: return 'forbidden'
        case 404: return 'notFound'
        default: return ''
      }
    },
    showDoc () {
      return this.isNew || !this.componentName
    }
  },
  props: {
    cache: Object,
    isNew: Boolean
  }
}
</script>
